<template>
  <div>
    <el-form label-width="80px"
             :model="form">
      <el-row :gutter="10">
        <!-- <el-col :span="6">
          <el-form-item label="来源名称:">
            <el-input v-model="form.keyword"
                      placeholder="请输入"
                      style="width:100%" />
          </el-form-item>
        </el-col> -->

        <el-col :span="12">
          <el-button type="primary"
                     @click="add">新增</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'paper_district_id', align: 'center', label: '试卷地区编号', width: '150', showToopic: false },
        { prop: 'paper_district_name', align: 'center', label: '试卷地区名称', width: '', showToopic: false },
        { prop: 'paper_district_desc', align: 'center', label: '试卷地区描述', width: '', showToopic: false },
        { prop: 'city_names', align: 'center', label: '城市', width: '', showToopic: false },
        { prop: 'subjects', align: 'center', label: '所有科目', width: '', showToopic: false },
        { prop: 'sort_order', align: 'center', label: '排序', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        subject_id: '',
        keyword: ''
      },
      pageName: 'slcz_paperdis_admin',
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let params = {
        page,
        limit
      }
      this.$http({
        url: '/api/v2/paper/district',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list.map(item => {
          item.subjects = ''
          item.subject_list.forEach(element => {
            item.subjects += `${element.subject_name},`
          })
          item.city_names = ''
          if (item.city_cn && item.city_cn.length) {
            item.city_names = item.city_cn.map(prev => {
              return prev.name
            }).join(',')
          }
          return item
        })

        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    editRow (row) {
      let paper_subjects = row.subject_list.map(item => {
        return item.subject_id
      })
      let form = {
        paper_district_id: row.paper_district_id,
        paper_district_name: row.paper_district_name,
        paper_district_desc: row.paper_district_desc,
        paper_subjects,
        city_ids: row.city_ids,
        sort_order: row.sort_order
      }

      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.getSubjectList()
      this.$refs.FormRef.getCityIds(form)
      this.$refs.FormRef.dialogVisible = true
    },
    add () {
      let form = {
        paper_district_name: '',
        paper_district_desc: '',
        paper_subjects: [],
        city_ids: []

      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.getSubjectList()
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((() => {
        this.$http({
          url: '/api/v2/paper/district_del',
          method: 'POST',
          data: {
            paper_district_id: row.paper_district_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  margin-bottom: 15px;
}
</style>